import { Switch as AntSwitch, SwitchProps as AntSwitchProps } from 'antd';

interface SwitchProps extends AntSwitchProps {
  label?: string;
  id: string;
  containerClassName?: string;
  testId?: string;
  checked?: boolean;
}

export const Switch = (props: SwitchProps) => {
  const { label, id, containerClassName, onChange, testId, ...rest } = props;

  const handleSwitchChange = (checked: boolean, e: any) => {
    // Update the hidden input value based on the switch state
    const hiddenInput = document.querySelector(`input[name='${id}']`);
    if (hiddenInput) {
      (hiddenInput as HTMLInputElement).checked = checked;
    }

    if (onChange) {
      onChange(checked, e);
    }
  };

  return (
    <div
      className={'flex items-center gap-2 ' + containerClassName}
      data-testid={testId || 'toggle'}>
      <AntSwitch {...rest} onChange={handleSwitchChange} />
      {label && <span>{label}</span>}
      <input
        checked={props.checked}
        className="hidden"
        defaultChecked={props.defaultChecked}
        id={id}
        name={id}
        readOnly
        type="checkbox"
      />
    </div>
  );
};
